import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginQuestion, LoginQuestionService, LoginResponseModel } from 'src/services-generated';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CloseCall } from 'src/services-generated/model/closeCall';
import { CloseCallService } from 'src/services-generated/api/closeCall.service';
import { FormControl } from '@angular/forms';
import { LocationService } from 'src/services-generated/api/location.service';
import { MatSelectChange } from '@angular/material/select';
import f  from 'odata-filter-builder';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { environment } from '@environments/environment'
import {Observable, Subscription} from 'rxjs';
import { AuthenticationService } from '@app/_services';
import {CloseCallCount} from '../../../services-generated/model/closeCallCount';
import { UserModule } from 'src/services-generated/model/UserModule.Model';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-live-close-call',
  templateUrl: './live-close-call.component.html',
  styleUrls: ['./live-close-call.component.scss']
})
export class LiveCloseCallComponent implements OnInit, OnDestroy{
  stateLoading = false; 
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @Output() closeCallData = new EventEmitter<string>();
  displayedColumns: string[] = [ "action", "title", "location", "occuredAt", "reportedBy", "reportedAt", "assignedTo", "assignedAt", "resolvedBy", "resolvedAt"];
  closeCalls: MatTableDataSource<CloseCall> = new MatTableDataSource<CloseCall>();
  apiCloseCallGetCountSubs: Subscription;
  apiCloseCallGetSubscription: Subscription;
  apiCloseCallServiceSubscription: Subscription;
  closeCallCountData: CloseCallCount = {
    createdToday : 0,
    closedToday : 0,
    overallActive : 0
  };
  closeCallCount:Number;
  lat = 55.861606;
  lng = -4.250313;
  resolvedCloseCallCount = 0;
  country = new FormControl();
  state = new FormControl([]);
  city = new FormControl();
  title = new FormControl();
  description = new FormControl();
  reportedBy = new FormControl();
  assignedTo = new FormControl();
  countries = ['UK'];
  states = [];
  cities = [];
  filteredStates: Observable<string[]>;
  odataQuery = "?$orderby=reportedAt desc&$filter=resolvedAt eq null";
  locationOdataQuery = "?$top=1000&$format=json&$orderby=createdAt desc&$filter=" + f.and().ne('city', null, false)
  .ne('state', null, false);
  markers:any[] = [];
  userRights: UserModule;
  loggedInUser: LoginResponseModel;
  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe,
    private closeCallService: CloseCallService,
    private locationService: LocationService) { }

  ngOnInit() {
    this.loggedInUser = this.authenticationService.currentUserValue;
    this.userRights = this.authenticationService.getUserRights("close");
    this.country.setValue('UK');
    this.resolvedCloseCallCount = 0;
    this.apiCloseCallGetSubscription = this.closeCallService.apiCloseCallGet(this.odataQuery).subscribe((closeCallResponse)=>{
      this.closeCalls.data = [...closeCallResponse];
      console.log(this.closeCalls.data)
      this.markers = closeCallResponse.map(closeCall => {
        if(closeCall.resolvedAt != null) {
          this.resolvedCloseCallCount++;
        }
        return {
          lat: closeCall.location.latitude,
          lng: closeCall.location.longitude,
          label: "",
          draggable: false,
          title: closeCall.title,
          description: closeCall.description,
          reportedBy: closeCall.reportedBy
        }
      });
      this.lat = this.markers[this.markers.length-1]!= null ? this.markers[this.markers.length-1].lat : 0;
      this.lng = this.markers[this.markers.length-1]!= null ? this.markers[this.markers.length-1].lng : 0;
      this.paginator.pageIndex = 0;
      this.closeCalls.paginator = this.paginator;
    });;
    this.filteredStates = this.state.valueChanges.pipe(
      switchMap(async (value) => await this._filter(value))
    );
    //in some time get Close calls again
    setTimeout(() => {
      if(this.router.url.includes("close-call/live")){
        this.ngOnInit();
      }
    }, environment.refreshRate);
    this.apiCloseCallServiceSubscription = this.closeCallService.apiCloseCallCountGet().subscribe((closeCallCountData : CloseCallCount)=>{
      this.closeCallCountData = closeCallCountData;
    })
  }

  ngOnDestroy() {
    this.apiCloseCallGetSubscription.unsubscribe();
    this.apiCloseCallServiceSubscription.unsubscribe();
  }

  private async _filter(value: any): Promise<string[]> {
    if(typeof(value) != 'string') {
      value = "";
    }
    const filterValue = value.toLowerCase();
    this.stateLoading = true;
    this.locationOdataQuery = "?$top=1000&$format=json&$orderby=createdAt desc&$filter=" + f.and().ne('city', null, false)
    .ne('state', null, false) + `and contains(state, '${filterValue}')`
    let locations = await this.locationService.apiLocationGet(this.locationOdataQuery).toPromise();
    this.stateLoading = false;
    if(locations) {
      this.states = [...new Set(locations.map(location => location.state))];
      this.cities = [...new Set(locations.filter(location =>
        {
          if(this.state.value.length != 0){
            return this.state.value.includes(location.state)
          } else {
            return true;
          }
        }
      ).map(location => location.city))];
      return this.states.filter(state => state.toLowerCase().includes(filterValue));
    }
  }

  handleStateChange(event: MatSelectChange){
    console.log(this.state.value);
    let filterString = f('and')
    .eq('resolvedAt', null)
    .in('location/state', this.state.value)
    .in('location/city', this.city.value)
    .toString();
    this.odataQuery = '?$format=json&$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }

  }

  handleCityChange(event: MatSelectChange){
    console.log(this.city.value);
    let filterString = f('and')
    .eq('resolvedAt', null)
    .in('location/state', this.state.value)
    .in('location/city', this.city.value)
    .toString();
    this.odataQuery = '?$format=json&$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }
  }

  handleFilterChange(event: MatSelectChange){
    let filterString = f.and()
      .eq('resolvedAt', null)
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .contains('title' , this.title.value? this.title.value : "")
      .contains('description' , this.description.value? this.description.value : "");
      if(this.reportedBy.value){
        filterString.contains('reportedBy/email' , this.reportedBy.value ? this.reportedBy.value : "")
      }
      if(this.assignedTo.value){
        filterString.contains('assignedTo/email' , this.assignedTo.value ? this.assignedTo.value : "")
      }

      this.odataQuery = '?$format=json&$filter=' + filterString
    if(filterString.toString() == ""){
      this.odataQuery = "";
    }
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     label: 'C',
  //     draggable: true
  //   });
  // }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  exportTableAsExcel() {
    let exportArray = [];
    this.closeCalls.data.forEach(closeCall => {
        let closeCallToExport = {
          "Title": closeCall.title,
          "Description": closeCall.description,
          "Incident Location Identifier": closeCall.occurenceLocationIdentifier.name,
          "Incident Location": closeCall.occurenceLocationDescription,
          "Incident At": closeCall.occurenceAt,
          "Reported Location": closeCall.location.label,
          "Reported By": closeCall.reportedBy.email,
          "Reported At": closeCall.reportedAt,
          "Assigned To": closeCall.assignedTo ? closeCall.assignedTo.email : '',
          "Assigned At": closeCall.assignedAt,
          "Resolved By": closeCall.resolvedBy ? closeCall.resolvedBy.email : '',
          "Resolved At": closeCall.resolvedAt
        }
        exportArray.push(closeCallToExport);
      });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    let currentTimestamp = new Date().toString();
    currentTimestamp = this.datePipe.transform(Date.now(),'yyyyMMdd_mmss');
    XLSX.writeFile(wb,  'closecalls_' + currentTimestamp + '.xlsx');
  }

  assignCloseCall(data: CloseCall){
    console.log("data" , data);
    this.router.navigate([`/supervisor/dashboard/close-call/assign/${data.id}`]);
  }

  resolveCloseCall(data: CloseCall){
    this.router.navigate([`/supervisor/dashboard/close-call/resolve/${data.id}`]);
  }

  closeCallAction(data: CloseCall){
    this.router.navigate([`/supervisor/dashboard/close-call/action/${data.id}`]);
  }

  onMapReady(map) {
    map.setOptions({
        fullscreenControl: 'true',
        fullscreenControlOptions: {
            position: 7
        }
    });
  }
}
