import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService, SiteOperativeService } from "src/services-generated";
import { Observable } from "rxjs";
import * as XLSX from "xlsx";
import f from "odata-filter-builder";
import { FormControl, Validators } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { SiteFamiliarisationModel } from "src/services-generated/model/siteFamiliarisation";
import { SiteFamilarisationsService } from "src/services-generated/api/site-familarisations.service";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as html2pdf from "html2pdf.js";
import { SiteFamiliarisationSiteQuestionModel } from "src/services-generated/model/siteFamiliarisationSiteQuestionModel";
import { SiteFamiliarisationQuestionModel } from "src/services-generated/model/siteFamiliarisationQuestionModel";
import { DatePipe } from "@angular/common";
import { AuthenticationService } from "@app/_services";
import { UserModule } from "src/services-generated/model/UserModule.Model";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export interface PeriodicElement {
  Name: string;
  Location: string;
  PostalCode: string;
}

@Component({
  selector: "app-sit-familiarisation",
  templateUrl: "./sit-familiarisation.component.html",
  styleUrls: ["./sit-familiarisation.component.scss"],
})
export class SitFamiliarisationComponent implements OnInit {
  title = "htmltopdf";
  siteFamiliarisationList: any[] = [];
  siteOperativeSites: any[] = [];

  siteOperativeUserId: any;
  siteOperatives = [];
  showTable = false;
  filteredSiteOperatives: Observable<string[]>;
  markers = [];
  siteQuestion: any;
  siteOperative = new FormControl("", Validators.required);
  error = "";
  odataQuery =
    "?$filter=" +
    f
      .and()
      .contains("role/name", "SiteOperative")
      .eq("isActive", true)
      .toString();
  displayedColumns: string[] = [
    "Name",
    "Location",
    "PostalCode",
    "isActive",
    "Approve",
    "action",
  ];
  // dataSource = ELEMENT_DATA;
  SiteFamiliarisationDetails: MatTableDataSource<SiteFamiliarisationModel> =
    new MatTableDataSource<SiteFamiliarisationModel>();
  SiteFamiliarisationDetailsList: SiteFamiliarisationModel[];
  sitefamiliarisationQuestion: SiteFamiliarisationQuestionModel[];
  sitefamiliarisationAnswer: SiteFamiliarisationQuestionModel[];
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;
  name: string;
  age: number;
  data: any[] = [];
  userRights: UserModule;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private siteOperativeService: SiteOperativeService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private familiarisationService: SiteFamilarisationsService,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService
  ) {
    this.name = "";
    this.age = 0;
  }

  ngOnInit() {
    this.userRights =
      this.authenticationService.getUserRights("Familiarisation");
    this.accountService
      .apiAccountGet(this.odataQuery)
      .subscribe((accountResponse) => {
        console.log("accountResponse:", accountResponse);
        let siteOperatives = accountResponse.map((siteOperative) => {
          return {
            id: siteOperative["id"],
            sentinelNumber:
              siteOperative["siteOperativeDetail"] != null
                ? siteOperative["siteOperativeDetail"]["sentinelNumber"]
                : "",
            email: siteOperative["email"],
            name: siteOperative["firstName"] + " " + siteOperative["lastName"],
          };
        });
        this.siteOperatives = siteOperatives;
      });
    this.filteredSiteOperatives = this.siteOperative.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.siteOperatives.filter((siteOperative) => {
      if (
        siteOperative.email &&
        siteOperative.sentinelNumber &&
        siteOperative.name
      ) {
        return (
          siteOperative.name.toLowerCase().indexOf(filterValue) === 0 ||
          siteOperative.email.toLowerCase().indexOf(filterValue) === 0 ||
          siteOperative.sentinelNumber.toLowerCase().indexOf(filterValue) === 0
        );
      } else {
        return false;
      }
    });
  }

  onSubmit() {
    console.log("details", this.siteOperative.value);
    this.siteOperativeUserId = parseInt(
      this.siteOperative.value.split(" | ")[0]
    );
    console.log("id", this.siteOperativeUserId);
    if (this.siteOperativeUserId) {
      this.familiarisationService
        .apiSiteFamiliarisationGetID(parseInt(this.siteOperativeUserId))
        .subscribe((response) => {
          if (response) this.showTable = true;
          if (response.result && response.result.length > 0) {
            this.SiteFamiliarisationDetails.data = response.result;
            this.SiteFamiliarisationDetailsList = response.result;
            console.log(
              "sitefamiliarisationDetails",
              this.SiteFamiliarisationDetailsList
            );
          } else {
            this.SiteFamiliarisationDetails.data = [];
            this.SiteFamiliarisationDetailsList = [];
            console.log("No records found");
          }
        });
    }
  }

  // getAllSiteOperativeSites(): void {
  //   this.familiarisationService.getallsitefamdata()
  //     .subscribe(
  //       (data) => {
  //         this.siteOperativeSites = data.result; // Assuming the payload contains the siteOperativeSites array
  //         console.log('Site Operative Sites:', this.siteOperativeSites);
  //       },
  //       (error) => {
  //         console.error('Error occurred:', error);
  //       }
  //     )
  // }
  downloadExcel(): void {
    this.familiarisationService.getallsitefamdata().subscribe(
      (data) => {
        const sites = data.result; // Assuming payload contains siteOperativeSites array
        this.exportAsExcelFile(sites, "site_operative_sites");
      },
      (error) => {
        console.error("Error occurred:", error);
      }
    );
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    XLSX.writeFile(workbook, `${excelFileName}.xlsx`);
  }

  clickMethod(e) {
    console.log(e);
    if (e.isApproved) {
      if (confirm("Are you sure to approve site familiarisation?"))
        this.siteApproval(e.isApproved, e.id);
    } else if (confirm("Are you sure to reject site familiarisation?"))
      this.siteApproval(e.isApproved, e.id);
  }

  siteApproval(isApproved, id) {
    this.familiarisationService
      .apiSiteOperativeSiteApproval(parseInt(id), isApproved)
      .subscribe((response) => {
        if (response.isSuccessful) alert("Site status saved");
      });
  }

  downloadAsPDF(SosID) {
    this.familiarisationService
      .apiSiteFamiliarisationQuestionGetID(SosID)
      .subscribe((questionresponse) => {
        console.log("Questions", questionresponse);

        this.sitefamiliarisationQuestion = questionresponse.result;
        console.log(this.sitefamiliarisationQuestion[0].siteOperativeSite);
        console.log(this.sitefamiliarisationAnswer);

        // Create the HTML template for the PDF content
        const htmlTemplate = `
        <html>
          <head>
          <style>
          *{
            font-size: 13px;
          }
          @page {
            /* Define the header and footer dimensions */
            size: A4;
            margin: 0;
            margin-top: 50px;
            margin-bottom: 40px;
            @top-center {
              // content: url(assets/images/Assets/CompanyLogo.png);
              width: 150px;
              height: 50px;
            }
            @bottom-center {
              content: counter(page) " of " counter(pages);
              font-size: 12px;
              margin-bottom: 10px;
            }
          }
          }
          body{
            display:flex;
            content-justify:center:
            align-item:center;
            font-family: Cambria, serif;
          }
          /* Define the styles for the table */
          .table {
            width: 100%;
           
          }
    
          .table2 {
            width: 100%;
            border: 1px solid black;
          }
    
          .th,
          .td {
            border: 1px solid black;
          }
      
        </style>
          </head>
          <body >
          <h1 style="color: #19183b;font-size: 30px;" >
          <img width = "240" height = "140" src="\\..\\..\\assets\\images\\Company_pdf_logo.png" alt="background image"/>Site Familiarisation
        </h1>
        <br>   
        <p style="color: #2b69b6; font-size: 16px; font-family: Cambria, serif;">
          RECORD OF ACCOMPANIED SITE FAMILIARISATION  TO BE COMPLETED BY  PERSON
          GIVING THE  SITE FAMILIARISATION (Onsite Advisor)  THEN TO BE SIGNED BY ALL
          PARTIES (Onsite Advisor and Onsite Operative)
        </p>
        <p style="color: #2b69b6;font-size: 14px; font-family: Cambria, serif;">
          This form is to be used as a record of all accompanied site
          familiarisation visits on Network  Rail Infrastructure for the purpose of
          site familiarisation prior to Possession Support duties.
        </p>
        <P style="color: #000000;font-size: 14px; font-weight:500; font-family: Cambria, serif;">
          This does not give permission to go on or Near The Line As defined in the
          Rule Book Handbook. Site familiarisation is to be conducted from
          authorised access points, Platforms or Lineside locations only. If a
          location requires you to go on or near the line then an SWP will be
          required as per 019 standard (NR/L2/OHS/019)
        </P>
        <p style="font-weight:700;font-size:14px;color: #2b69b6; font-family: Cambria, serif;">
        Site Visit Process: <br>The Onsite Advisor conducting the site visit is to
        take the Onsite Operative requiring the site visit to the access location
        as detailed in the SWP / Briefing pack that is to be used on the shift
        that the site visit is for. The Onsite Advisor conducting the site visit
        is to ensure guidance is given if needed, and is only to sign off this visit once confident that the Onsite Operative receiving
        the visit has a full understanding of what will be required on the day /
        night of the shift and is fully familiar with all duties expected of them.
      </p>

<br>
<h3 style="font-weight:600;font-size:18px;color: #2b69b6;">PSS Route Familiarisation Details for Possession Protection</h3>
          <table border="2" bordercolor="black" class="table2" >
            <thead style="font-size:18px;">
            <table class="table2" style="font-size:16px;height:40px">
              <tr>
                <th><span style="font-weight: 200;">Date of Site Visit: </span>${this.datePipe.transform(
                  this.sitefamiliarisationQuestion[0].siteOperativeSite
                    .modifiedAt,
                  "MMMM dd yyyy, h:mm:ss a"
                )}</th>
                <th style="border:1px solid black"><span style="font-weight: 200;">Week No:</span> ${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite.weekNo
                }</th>
              </tr>
              </table>
              <table class="table2" style="height:40px;">
              <tr>
                <th><span style="font-weight: 200;">Onsite Advisor’s Name: </span>${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite
                    .siteAdvisorDetail.user.firstName +
                  " " +
                  this.sitefamiliarisationQuestion[0].siteOperativeSite
                    .siteAdvisorDetail.user.lastName
                }</th>
              </tr>
              </table>
              <table class="table2" style="height:40px;">
              <tr>
                <th><span style="font-weight: 200;">Onsite Operative’s Name: </span>${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite
                    .siteOperativeDetail.user.firstName +
                  " " +
                  this.sitefamiliarisationQuestion[0].siteOperativeSite
                    .siteOperativeDetail.user.lastName
                }</th>
              </tr>
              </table >
              <table class="table2" style="height:40px;">
              <tr>
                <th><span style="font-weight: 200;">Won Item No: </span>${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite
                    .wonItemNo
                }</th>
                <th style="border:1px solid black"><span style="font-weight: 200;">Site Name: </span>${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite.site
                    .name
                }</th>
              </tr>
              </table>
              <table class="table2" style="height:40px;">
              <tr>
                <th><span style="font-weight: 200;">Primary Location: </span>${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite.site
                    .location
                }</th>
                <th style="border:1px solid black"><span style="font-weight: 200;">Secondary Location: </span>${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite.site
                    .secondaryLocation
                }</th>
              </tr>
              </table>
              <table class="table2" style="height:40px;">
              <tr>
                <th><span style="font-weight: 200;">Infrastructure Id: </span>${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite.site
                    .infrastructureId
                }</th>
                <th style="border:1px solid black"><span style="font-weight: 200;">Post Code: </span>${
                  this.sitefamiliarisationQuestion[0].siteOperativeSite.site
                    .postalCode
                }</th>
              </tr>
              </table>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <h5 style="margin-left:260px;">PRE-SITE VISIT CHECKS</h5>
<div style="display: flex;
justify-content: center;">
<table class="table" >
<tbody>
  ${this.sitefamiliarisationQuestion
    .map(
      (question, index) => `
    <tr>
      <td style="font-size: 11px;">${question.siteQuestion.question}</td>
      <td >
      <div style="border:1px solid black;width:50px;max-height:20px;font-size: 11px; margin-left: 2px">
      ${question.answer ? "Yes" : "No"}
        </div>
      </td>
    </tr>
    <tr>
      <td ><br></td>
    </tr>
  `
    )
    .join("")}
</tbody>
</table>
</div>

    
     
   
            <p  style="font-weight:600">
            IF FOR ANY REASON YES CANNOT BE TICKED IN THE BOXES ABOVE THEN A REPORT IS
            TO BE MADE TO YOUR LINE MANAGER (VIA YOUR ON CALL) STATING THE REASONS FOR THIS AND THE LINE
            MANAGER IS TO REVIEW ALTERNATIVE SITE VISIT / STAFF
          </p><br><br><br><br> <br>
          <br> <br>
          <br>
          ${""}
          </thead>
          <table class="table2" >
              <tr>
              <td style="font-weight:600;font-size:16px;color: #2b69b6;">
              <p >
              I can confirm that I am site familiar with the area of the location.
              I have been briefed on the protection arrangements of the above possession.</p
              ><br>
              <p>Onsite Operative Signature: <img  src=${
                this.sitefamiliarisationQuestion[0].siteOperativeSite.operativeSignUrl.split(
                  "swgrazureuser/"
                )[1]
              } alt="background image" width="100px" height="100px" /></p>
              <p>Date: ${
                this.sitefamiliarisationQuestion[0].siteOperativeSite.familiarisationDate.split(
                  "T"
                )[0]
              }</p>
              <p >
              I can confirm that I have provided site familiarisation of the area of the location. I have briefed the protection arrangements of the above possession.</p
              >
              <p>Onsite Advisor Signature: <img  src=${
                this.sitefamiliarisationQuestion[0].siteOperativeSite.advisorSignUrl.split(
                  "swgrazureuser/"
                )[1]
              } alt="background image" width="100px" height="100px"/></p>
              <p>Date: ${
                this.sitefamiliarisationQuestion[0].siteOperativeSite.familiarisationDate.split(
                  "T"
                )[0]
              }</p>
              <div style="border-top: 2px solid black; width: 100%; display: flex; flex-direction: column;">
              <label style=" margin-left: auto; margin-right: auto; ">For office use</label>
              
              <p >
              Site visit reviewed & shift authorised by Delivery Manager: …………………..
              </p>
              <p>Date: ……………………………………………………………………………..</p>
              </div>
              </td>
              </tr>
              </table>
      </table>
          </body>
        </html>
      `;

        const pdfOptions = {
          filename: "Site Familiarisation.pdf",
          image: { type: "png" },
          html2canvas: {},
          jsPDF: { orientation: "landscape" as const },
          content: ["Some text goes here...", "Some more text goes here..."],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [10, 5, 0, 0],
              image: "",
              background: "blue",
            },
          },
        };

        // Add HTML content and generate the PDF
        const element = document.createElement("div");
        element.innerHTML = htmlTemplate;
        html2pdf()
          .set({
            margin: [15, 20],
            filename: pdfOptions.filename,
            image: pdfOptions.image,
            html2canvas: pdfOptions.html2canvas,
            jsPDF: pdfOptions.styles.header,
            pagebreak: { mode: ["avoid-all", "css", "legacy"] },
          })
          .from(element)
          .save();
      });
  }

  transferData() {
    this.siteOperativeUserId = this.siteOperative.value.split(" | ")[0];
    const data = this.siteOperativeUserId;
    console.log("details", data);
    this.router.navigate(["/supervisor/sitefamiliarisationPost", { id: data }]);
  }
}
